import React, { useEffect, useState } from 'react';
import { Alert, Box } from '@mui/material';
import CaseList from './CaseList';
import CarContainerCard from './CarContainerCard';
import LoadingCar from '../../../Loading/LoadingCar';
import { useLicensePlateStore } from '../../../../hooks/store/useLicensePlateStore';
import { UseSalesForceAlert } from '../../../Alert/UseSalesForceAlert';

const SearchResultsDisplay = () => {
  const { vehicleData, caseHistory, fetchLoadingState } = useLicensePlateStore();
  const [weightAlert, setWeightAlert] = useState(false);
  const [lengthAlert, setLengthAlert] = useState(false);

  useEffect(() => {
    if (vehicleData) {
      const weight = vehicleData?.vehicleData?.technicalData?.weight;
      const vehicleType = vehicleData?.vehicleData?.bodyType;

      setWeightAlert(weight > 3500);
      setLengthAlert(vehicleType?.length > 20);
    } else {
      setWeightAlert(false);
      setLengthAlert(false);
    }
  }, [vehicleData]);

  if (fetchLoadingState) {
    return <LoadingCar />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {vehicleData && weightAlert && (
        <Alert severity="error" sx={{ backgroundColor: '#ff5757', boxShadow: 2, color: 'black' }}>
          <b>Vekten på bilen er over 3500. Dette er en tungbil, saken må opprettes av team truck!</b>
        </Alert>
      )}
      {vehicleData && lengthAlert && <UseSalesForceAlert />}
      {vehicleData && <CarContainerCard data={vehicleData} />}
      {caseHistory && <CaseList caseData={caseHistory} />}
    </Box>
  );
};

export default SearchResultsDisplay;
