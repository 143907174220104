export const coverCauses = [
  {
    name: 'Collision',
    parent__r: {
      name: 'Accident',
      nameTranslation: 'Ulykke',
    },
    level__c: '3',
    coverCauseId: 'Collision',
    code__c: '101',
    nameTranslation: 'Kollisjon',
  },
  {
    name: 'Other accident',
    parent__r: {
      name: 'Accident',
      nameTranslation: 'Ulykke',
    },
    level__c: '3',
    coverCauseId: 'OtherAccident',
    code__c: '106',
    nameTranslation: 'Annet uhell',
  },
  {
    name: 'Door locks not opening',
    parent__r: {
      name: 'Body / accessories',
      nameTranslation: 'Chassis/ påbygg',
    },
    level__c: '4',
    coverCauseId: 'DoorLocksNotOpening',
    code__c: '202',
    nameTranslation: 'Utelåst',
  },
  {
    name: 'Exhaust pipe problem',
    parent__r: {
      name: 'Body / accessories',
      nameTranslation: 'Chassis/ påbygg',
    },
    level__c: '4',
    coverCauseId: 'ExhaustPipeProblem',
    code__c: '203',
    nameTranslation: 'Eksosanlegg',
  },
  {
    name: 'Windscreen / window broken',
    parent__r: {
      name: 'Body / accessories',
      nameTranslation: 'Chassis/ påbygg',
    },
    level__c: '4',
    coverCauseId: 'WindscreenOrWindowBroken',
    code__c: '204',
    nameTranslation: 'Rute / vindusvisker',
  },
  {
    name: 'Brakes not connecting',
    parent__r: {
      name: 'Brakes',
      nameTranslation: 'Bremser',
    },
    level__c: '4',
    coverCauseId: 'BrakesNotConnecting',
    code__c: '206',
    nameTranslation: 'Bremser virker ikke',
  },
  {
    name: 'Abnormal noise from brakes',
    parent__r: {
      name: 'Brakes',
      nameTranslation: 'Bremser',
    },
    level__c: '4',
    coverCauseId: 'AbnormalNoiseBrakes',
    code__c: '208',
    nameTranslation: 'Bremser, ulyd',
  },
  {
    name: 'Brakes not disconnecting',
    parent__r: {
      name: 'Brakes',
      nameTranslation: 'Bremser',
    },
    level__c: '4',
    coverCauseId: 'BrakesNotDisconnecting',
    code__c: '207',
    nameTranslation: 'Bremser sitter fast',
  },
  {
    name: 'Body / accessories',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'BodyAndAccessories',
    code__c: '201',
    nameTranslation: 'Chassis/ påbygg',
  },
  {
    name: 'Brakes',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'Brakes',
    code__c: '205',
    nameTranslation: 'Bremser',
  },
  {
    name: 'Electrical',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'Electrical',
    code__c: '212',
    nameTranslation: 'Elektrisk problem',
  },
  {
    name: 'Engine',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'Engine',
    code__c: '217',
    nameTranslation: 'Motorfeil',
  },
  {
    name: 'Hydraulics problem',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'HydraulicsProblem',
    code__c: '246',
    nameTranslation: 'Hydraulikk',
  },
  {
    name: 'Leak',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'Leak',
    code__c: '221',
    nameTranslation: 'Lekkasje',
  },
  {
    name: 'Steering',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'Steering',
    code__c: '229',
    nameTranslation: 'Styring',
  },
  {
    name: 'Suspension / chassis',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'SuspensionOrChassis',
    code__c: '232',
    nameTranslation: 'Hjuloppheng',
  },
  {
    name: 'Tire',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'Tire',
    code__c: '236',
    nameTranslation: 'Dekk',
  },
  {
    name: 'Warning lights',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'WarningLights',
    code__c: '243',
    nameTranslation: 'Varsellampe',
  },
  {
    name: 'Other breakdown',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'OtherBreakdown',
    code__c: '226',
    nameTranslation: 'Driftsstans, annet',
  },
  {
    name: 'Does not start',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'DoesNotStart',
    code__c: '209',
    nameTranslation: 'Startvansker',
  },
  {
    name: 'Transmission',
    parent__r: {
      name: 'Breakdown',
      nameTranslation: 'Driftsstans',
    },
    level__c: '3',
    coverCauseId: 'Transmission',
    code__c: '240',
    nameTranslation: 'Gir / Drivverk / Clutch',
  },
  {
    name: 'Collision with moose/deer',
    parent__r: {
      name: 'Collision',
      nameTranslation: 'Kollisjon',
    },
    level__c: '4',
    coverCauseId: 'CollisionMooseOrDeer',
    code__c: '104',
    nameTranslation: 'Kollisjon med vilt',
  },
  {
    name: 'Collision with another vehicle',
    parent__r: {
      name: 'Collision',
      nameTranslation: 'Kollisjon',
    },
    level__c: '4',
    coverCauseId: 'CollisionAnotherVehicle',
    code__c: '102',
    nameTranslation: 'Kollisjon med annen bil',
  },
  {
    name: 'Collision with stationary object',
    parent__r: {
      name: 'Collision',
      nameTranslation: 'Kollisjon',
    },
    level__c: '4',
    coverCauseId: 'CollisionStationaryObject',
    code__c: '103',
    nameTranslation: 'Kollisjon med gjenstand',
  },
  {
    name: 'Collision with other animal',
    parent__r: {
      name: 'Collision',
      nameTranslation: 'Kollisjon',
    },
    level__c: '4',
    coverCauseId: 'CollisionOtherAnimal',
    code__c: '105',
    nameTranslation: 'Kollisjon med annet dyr',
  },
  {
    name: 'Theft',
    parent__r: {
      name: 'Crime',
      nameTranslation: 'Kriminalitet',
    },
    level__c: '3',
    coverCauseId: 'Theft',
    code__c: '301',
    nameTranslation: 'Tyveri',
  },
  {
    name: 'Vandalism',
    parent__r: {
      name: 'Crime',
      nameTranslation: 'Kriminalitet',
    },
    level__c: '3',
    coverCauseId: 'Vandalism',
    code__c: '302',
    nameTranslation: 'Hærverk',
  },
  {
    name: 'Does not start, has no electricity, away from home',
    parent__r: {
      name: 'Does not start',
      nameTranslation: 'Startvansker',
    },
    level__c: '4',
    coverCauseId: 'NotStartNoElectricityNotHome',
    code__c: '210',
    nameTranslation: 'Startvansker på vei',
  },
  {
    name: 'Does not start, has no electricity, at home address',
    parent__r: {
      name: 'Does not start',
      nameTranslation: 'Startvansker',
    },
    level__c: '4',
    coverCauseId: 'NotStartNoElectricityAtHomes',
    code__c: '211',
    nameTranslation: 'Startvansker hjemme',
  },
  {
    name: 'Headlights/other lights broken',
    parent__r: {
      name: 'Electrical',
      nameTranslation: 'Elektrisk problem',
    },
    level__c: '4',
    coverCauseId: 'HeadlightsOrOtherLightsBroken',
    code__c: '216',
    nameTranslation: 'Lys',
  },
  {
    name: 'Malfunction of electrical device',
    parent__r: {
      name: 'Electrical',
      nameTranslation: 'Elektrisk problem',
    },
    level__c: '4',
    coverCauseId: 'MalfunctionOfElectricalDevice',
    code__c: '214',
    nameTranslation: 'Feil på elektrisk utstyr',
  },
  {
    name: 'Lost electricity when driving',
    parent__r: {
      name: 'Electrical',
      nameTranslation: 'Elektrisk problem',
    },
    level__c: '4',
    coverCauseId: 'LostElectricityWhileDriving',
    code__c: '213',
    nameTranslation: 'Mistet strøm under kjøring',
  },
  {
    name: 'Heating/air con not working',
    parent__r: {
      name: 'Electrical',
      nameTranslation: 'Elektrisk problem',
    },
    level__c: '4',
    coverCauseId: 'HeatingOrAirConNotWorking',
    code__c: '215',
    nameTranslation: 'Varme / AC virker ikke',
  },
  {
    name: 'Battery',
    parent__r: {
      name: 'Electronics',
      nameTranslation: 'Elektronikk',
    },
    level__c: '4',
    coverCauseId: 'LH8EAN',
    code__c: null,
    nameTranslation: 'Elektronikk - Batteri',
  },
  {
    name: 'Lost power suddently',
    parent__r: {
      name: 'Engine',
      nameTranslation: 'Motorfeil',
    },
    level__c: '4',
    coverCauseId: 'LostPowerSuddently',
    code__c: '218',
    nameTranslation: 'Motorstopp',
  },
  {
    name: 'Smoke/Overheating',
    parent__r: {
      name: 'Engine',
      nameTranslation: 'Motorfeil',
    },
    level__c: '4',
    coverCauseId: 'SmokeOrOverheating',
    code__c: '219',
    nameTranslation: 'Røykutvikling',
  },
  {
    name: 'Abnormal noise from engine',
    parent__r: {
      name: 'Engine',
      nameTranslation: 'Motorfeil',
    },
    level__c: '4',
    coverCauseId: 'AbnormalNoiseEngine',
    code__c: '220',
    nameTranslation: 'Ulyd fra motor',
  },
  {
    name: 'Stalled while driving',
    parent__r: {
      name: 'Engine',
      nameTranslation: 'Motorfeil',
    },
    level__c: '4',
    coverCauseId: 'StalledWhileDriving',
    code__c: '247',
    nameTranslation: 'Motorstopp under kjøring',
  },
  {
    name: 'Fire when stationary',
    parent__r: {
      name: 'Fire',
      nameTranslation: 'Brann',
    },
    level__c: '4',
    coverCauseId: 'FireStationary',
    code__c: '609',
    nameTranslation: 'Brann i parkert bil',
  },
  {
    name: 'Fire when driving',
    parent__r: {
      name: 'Fire',
      nameTranslation: 'Brann',
    },
    level__c: '4',
    coverCauseId: 'FireDriving',
    code__c: '608',
    nameTranslation: 'Brann under kjøring',
  },
  {
    name: 'Wrong fuel',
    parent__r: {
      name: 'Fuel',
      nameTranslation: 'Drivstofftom EL',
    },
    level__c: '4',
    coverCauseId: 'WrongFuel',
    code__c: '403',
    nameTranslation: 'Feilfylling',
  },
  {
    name: 'Out of fuel',
    parent__r: {
      name: 'Fuel',
      nameTranslation: 'Drivstofftom EL',
    },
    level__c: '4',
    coverCauseId: 'OutOfFuel',
    code__c: '402',
    nameTranslation: 'Drivstofftom',
  },
  {
    name: 'Driveway damaged',
    parent__r: {
      name: 'Infrastructure',
      nameTranslation: 'Vei',
    },
    level__c: '3',
    coverCauseId: 'DrivewayDamaged',
    code__c: '502',
    nameTranslation: 'Veien skadet',
  },
  {
    name: 'Road blocked',
    parent__r: {
      name: 'Infrastructure',
      nameTranslation: 'Vei',
    },
    level__c: '3',
    coverCauseId: 'RoadBlocked',
    code__c: '501',
    nameTranslation: 'Veien sperret',
  },
  {
    name: 'Keys inside locked car',
    parent__r: {
      name: 'Keys',
      nameTranslation: 'Nøkkel',
    },
    level__c: '4',
    coverCauseId: 'KeysInsideLockedCar',
    code__c: '406',
    nameTranslation: 'Innelåst nøkkel',
  },
  {
    name: 'Keys inside locked car, engine running',
    parent__r: {
      name: 'Keys',
      nameTranslation: 'Nøkkel',
    },
    level__c: '4',
    coverCauseId: 'KeysInsideLockedCarEngineRunning',
    code__c: '407',
    nameTranslation: 'Nøkkel innelåst, motor går',
  },
  {
    name: 'Keys lost',
    parent__r: {
      name: 'Keys',
      nameTranslation: 'Nøkkel',
    },
    level__c: '4',
    coverCauseId: 'KeysLost',
    code__c: '408',
    nameTranslation: 'Mistet nøkkel',
  },
  {
    name: 'Leaking coolant',
    parent__r: {
      name: 'Leak',
      nameTranslation: 'Lekkasje',
    },
    level__c: '4',
    coverCauseId: 'LeakingCoolant',
    code__c: '223',
    nameTranslation: 'Kjølevæskelekkasje',
  },
  {
    name: 'Leaking fuel',
    parent__r: {
      name: 'Leak',
      nameTranslation: 'Lekkasje',
    },
    level__c: '4',
    coverCauseId: 'LeakingFuel',
    code__c: '225',
    nameTranslation: 'Drivstofflekkasje',
  },
  {
    name: 'Leaking oil',
    parent__r: {
      name: 'Leak',
      nameTranslation: 'Lekkasje',
    },
    level__c: '4',
    coverCauseId: 'LeakingOil',
    code__c: '224',
    nameTranslation: 'Oljelekkasje',
  },
  {
    name: 'Leaking air',
    parent__r: {
      name: 'Leak',
      nameTranslation: 'Lekkasje',
    },
    level__c: '4',
    coverCauseId: 'LeakingAir',
    code__c: '222',
    nameTranslation: 'Luftlekkasje',
  },
  {
    name: 'Driver sick / injured',
    parent__r: {
      name: 'Other',
      nameTranslation: 'Annet',
    },
    level__c: '3',
    coverCauseId: 'DriverSickOrInjured',
    code__c: '604',
    nameTranslation: 'Sjåfør syk',
  },
  {
    name: 'Fire',
    parent__r: {
      name: 'Other',
      nameTranslation: 'Annet',
    },
    level__c: '3',
    coverCauseId: 'Fire',
    code__c: '607',
    nameTranslation: 'Brann',
  },
  {
    name: 'Keys broken',
    parent__r: {
      name: 'Other',
      nameTranslation: 'Annet',
    },
    level__c: '3',
    coverCauseId: 'KeysBroken',
    code__c: '610',
    nameTranslation: 'Ødelagt nøkkel',
  },
  {
    name: 'Police request',
    parent__r: {
      name: 'Other',
      nameTranslation: 'Annet',
    },
    level__c: '3',
    coverCauseId: 'PoliceRequest',
    code__c: '606',
    nameTranslation: 'Politirekvirert',
  },
  {
    name: 'Liability damage',
    parent__r: {
      name: 'Other',
      nameTranslation: 'Annet',
    },
    level__c: '3',
    coverCauseId: 'LiabilityDamage',
    code__c: '605',
    nameTranslation: 'Ansvarsskade',
  },
  {
    name: 'Transportation',
    parent__r: {
      name: 'Other',
      nameTranslation: 'Annet',
    },
    level__c: '3',
    coverCauseId: 'Transportation',
    code__c: '601',
    nameTranslation: 'Transport',
  },
  {
    name: 'Driven off road, drivable',
    parent__r: {
      name: 'Other accident',
      nameTranslation: 'Annet uhell',
    },
    level__c: '4',
    coverCauseId: 'DrivenOffRoadDrivable',
    code__c: '107',
    nameTranslation: 'Utforkjøring, trolig kjørbar',
  },
  {
    name: 'Driven off road, non-drivable',
    parent__r: {
      name: 'Other accident',
      nameTranslation: 'Annet uhell',
    },
    level__c: '4',
    coverCauseId: 'DrivenOffRoadNotDrivable',
    code__c: '108',
    nameTranslation: 'Utforkjøring, ikke kjørbar',
  },
  {
    name: 'Stuck on road / driveway',
    parent__r: {
      name: 'Other accident',
      nameTranslation: 'Annet uhell',
    },
    level__c: '4',
    coverCauseId: 'StuckOnRoadOrDriveway',
    code__c: '109',
    nameTranslation: 'Fastkjørt på vei',
  },
  {
    name: 'Trailer broken',
    parent__r: {
      name: 'Other breakdown',
      nameTranslation: 'Driftsstans, annet',
    },
    level__c: '4',
    coverCauseId: 'TrailerBroken',
    code__c: '227',
    nameTranslation: 'Tilhenger',
  },
  {
    name: 'Problem connecting to trailer',
    parent__r: {
      name: 'Other breakdown',
      nameTranslation: 'Driftsstans, annet',
    },
    level__c: '4',
    coverCauseId: 'ProblemConnectingTrailer',
    code__c: '228',
    nameTranslation: 'Tilhenger, tilkoblingsproblem',
  },
  {
    name: 'Accident',
    parent__r: {
      name: 'RSA',
      nameTranslation: 'Veihjelp',
    },
    level__c: '2',
    coverCauseId: 'Accident',
    code__c: '100',
    nameTranslation: 'Ulykke',
  },
  {
    name: 'Crime',
    parent__r: {
      name: 'RSA',
      nameTranslation: 'Veihjelp',
    },
    level__c: '2',
    coverCauseId: 'Crime',
    code__c: '300',
    nameTranslation: 'Kriminalitet',
  },
  {
    name: 'User error',
    parent__r: {
      name: 'RSA',
      nameTranslation: 'Veihjelp',
    },
    level__c: '2',
    coverCauseId: 'UserError',
    code__c: '400',
    nameTranslation: 'Brukerfeil',
  },
  {
    name: 'Infrastructure',
    parent__r: {
      name: 'RSA',
      nameTranslation: 'Veihjelp',
    },
    level__c: '2',
    coverCauseId: 'Infrastructure',
    code__c: '500',
    nameTranslation: 'Vei',
  },
  {
    name: 'Other',
    parent__r: {
      name: 'RSA',
      nameTranslation: 'Veihjelp',
    },
    level__c: '2',
    coverCauseId: 'Other',
    code__c: '600',
    nameTranslation: 'Annet',
  },
  {
    name: 'Breakdown',
    parent__r: {
      name: 'RSA',
      nameTranslation: 'Veihjelp',
    },
    level__c: '2',
    coverCauseId: 'Breakdown',
    code__c: '200',
    nameTranslation: 'Driftsstans',
  },
  {
    name: 'Ignition/steering lock stuck',
    parent__r: {
      name: 'Steering',
      nameTranslation: 'Styring',
    },
    level__c: '4',
    coverCauseId: 'IgnitionOrSteeringLockStuck',
    code__c: '231',
    nameTranslation: 'Tenning/ rattlås',
  },
  {
    name: 'Steering went heavy suddenly',
    parent__r: {
      name: 'Steering',
      nameTranslation: 'Styring',
    },
    level__c: '4',
    coverCauseId: 'SteeringHeavySuddenly',
    code__c: '230',
    nameTranslation: 'Styring',
  },
  {
    name: 'Air suspension broken',
    parent__r: {
      name: 'Suspension / chassis',
      nameTranslation: 'Hjuloppheng',
    },
    level__c: '4',
    coverCauseId: 'AirSuspensionBroken',
    code__c: '233',
    nameTranslation: 'Luftfjæring',
  },
  {
    name: 'Bearing broken',
    parent__r: {
      name: 'Suspension / chassis',
      nameTranslation: 'Hjuloppheng',
    },
    level__c: '4',
    coverCauseId: 'BearingBroken',
    code__c: '235',
    nameTranslation: 'Hjuloppheng ødelagt',
  },
  {
    name: 'Broken spring',
    parent__r: {
      name: 'Suspension / chassis',
      nameTranslation: 'Hjuloppheng',
    },
    level__c: '4',
    coverCauseId: 'BrokenSpring',
    code__c: '234',
    nameTranslation: 'Fjæring',
  },
  {
    name: 'Wheel fell off',
    parent__r: {
      name: 'Tire',
      nameTranslation: 'Dekk',
    },
    level__c: '4',
    coverCauseId: 'WheelFellOff',
    code__c: '237',
    nameTranslation: 'Hjul falt av',
  },
  {
    name: 'Puncture while driving',
    parent__r: {
      name: 'Tire',
      nameTranslation: 'Dekk',
    },
    level__c: '4',
    coverCauseId: 'TirePunctureDriving',
    code__c: '238',
    nameTranslation: 'Punktering under kjøring',
  },
  {
    name: 'Puncture when stationary',
    parent__r: {
      name: 'Tire',
      nameTranslation: 'Dekk',
    },
    level__c: '4',
    coverCauseId: 'TirePunctureWhenStationary',
    code__c: '239',
    nameTranslation: 'Punktert mens parkert',
  },
  {
    name: 'Abnormal noise from transmission',
    parent__r: {
      name: 'Transmission',
      nameTranslation: 'Gir / Drivverk / Clutch',
    },
    level__c: '4',
    coverCauseId: 'AbnormalNoiseTransmission',
    code__c: '242',
    nameTranslation: 'Ulyd fra girkasse',
  },
  {
    name: 'Engine running, car not moving',
    parent__r: {
      name: 'Transmission',
      nameTranslation: 'Gir / Drivverk / Clutch',
    },
    level__c: '4',
    coverCauseId: 'EngineRunningNotMoving',
    code__c: '241',
    nameTranslation: 'Motor går, bil kjører ikke',
  },
  {
    name: 'Transportation of drivable car',
    parent__r: {
      name: 'Transportation',
      nameTranslation: 'Transport',
    },
    level__c: '4',
    coverCauseId: 'TransportationDriveable',
    code__c: '602',
    nameTranslation: 'Transport av kjørbar bil',
  },
  {
    name: 'Transportation of non-drivable car',
    parent__r: {
      name: 'Transportation',
      nameTranslation: 'Transport',
    },
    level__c: '4',
    coverCauseId: 'TransportationNonDriveable',
    code__c: '603',
    nameTranslation: 'Transport av ikke kjørbar bil',
  },
  {
    name: 'Illegal parking',
    parent__r: {
      name: 'User error',
      nameTranslation: 'Brukerfeil',
    },
    level__c: '3',
    coverCauseId: 'IllegalParking',
    code__c: '404',
    nameTranslation: 'Feilparkering',
  },
  {
    name: 'Other user error',
    parent__r: {
      name: 'User error',
      nameTranslation: 'Brukerfeil',
    },
    level__c: '3',
    coverCauseId: 'OtherUserError',
    code__c: '409',
    nameTranslation: 'Annen brukerfeil',
  },
  {
    name: 'Keys',
    parent__r: {
      name: 'User error',
      nameTranslation: 'Brukerfeil',
    },
    level__c: '3',
    coverCauseId: 'Keys',
    code__c: '405',
    nameTranslation: 'Nøkkel',
  },
  {
    name: 'Fuel',
    parent__r: {
      name: 'User error',
      nameTranslation: 'Brukerfeil',
    },
    level__c: '3',
    coverCauseId: 'Fuel',
    code__c: '401',
    nameTranslation: 'Drivstofftom EL',
  },
  {
    name: 'Warning lights Yellow',
    parent__r: {
      name: 'Warning lights',
      nameTranslation: 'Varsellampe',
    },
    level__c: '4',
    coverCauseId: 'WarningLightsYellow',
    code__c: '245',
    nameTranslation: 'Gul varsellampe',
  },
  {
    name: 'Warning lights Red',
    parent__r: {
      name: 'Warning lights',
      nameTranslation: 'Varsellampe',
    },
    level__c: '4',
    coverCauseId: 'WarningLightsRed',
    code__c: '244',
    nameTranslation: 'Rød varsellampe',
  },
];

export const categoryTranslations = {
  Transport: 'Transport',
  Collision: 'Kollisjon',
  'Does not start': 'Startvansker',
  Electrical: 'Elektrisk problem',
  'Body / accessories': 'Chassis/ påbygg',
  Engine: 'Motorfeil',
  Steering: 'Styring',
  Brakes: 'Bremser',
  'Suspension / chassis': 'Hjuloppheng',
  Tire: 'Dekk',
  Leak: 'Lekkasje',
  Transmission: 'Gir / Drivverk / Clutch',
  Fire: 'Brann',
  Keys: 'Nøkkel',
  'Warning lights': 'Varsellampe',
  'Out of charge': 'Drivstofftom EL',
  'Other breakdown': 'Driftsstans, annet',
  'Other accident': 'Annet uhell',
  Crime: 'Kriminelt',
};
