import { Alert, AlertTitle, Box, Typography } from '@mui/material';

export const AlertContent = () => (
  <Box marginTop={2}>
    <Typography variant="body1">Appen kan ikke bli brukt til å opprette sak på dette kjøretøyet</Typography>
    <Typography variant="body1">Vi er kjent med feilen og jobber med en løsning.</Typography>
  </Box>
);

export const UseSalesForceAlert = () => {
  return (
    <Alert severity="error" sx={{ backgroundColor: '#FFCDD2', boxShadow: 2, color: 'black' }}>
      <AlertTitle>Bruk SalesForce for opprettelse av saken.</AlertTitle>
      <AlertContent />
    </Alert>
  );
};
