import { Alert, Box, Typography } from '@mui/material';

export const LocationHelperText = ({ severity, message }) => {
  return (
    <Box
      sx={{
        marginTop: 1,
      }}
    >
      <Alert variant={severity === 'success' ? 'filled' : 'standard'} severity={severity}>
        <Typography variant="caption">{message}</Typography>
      </Alert>
    </Box>
  );
};
