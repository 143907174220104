import { Autocomplete, Box, Divider, FormControl, TextField, Tooltip, Typography } from '@mui/material';
import { categoryTranslations, coverCauses } from '../../../../options/CoverCasue';
import { useFormikContext } from 'formik';
import React from 'react';

export const CoverCauseSelector = () => {
  const { values, errors, setFieldValue } = useFormikContext();

  const formattedCoverCauses = coverCauses.map((coverCauseOptions) => ({
    name: coverCauseOptions.name,
    nameTranslation: coverCauseOptions.nameTranslation,
    coverCauseId: coverCauseOptions.coverCauseId || coverCauseOptions.nameTranslation,
    level: coverCauseOptions.level__c,
    parent: coverCauseOptions.parent__r?.nameTranslation || 'Ukjent kategori',
  }));

  const coverCauseOptions = formattedCoverCauses.filter((option) => option.level === '4');

  return (
    <Tooltip title="Velg hva som har skjedd med kjøretøyet" placement="top">
      <FormControl fullWidth variant="outlined" error={Boolean(errors.incident?.incidentCause)}>
        <Autocomplete
          options={coverCauseOptions}
          groupBy={(option) => option.parent}
          getOptionLabel={(option) => option.nameTranslation || option.name}
          value={coverCauseOptions.find((option) => option.coverCauseId === values.incident?.incidentCause) || null}
          onChange={(event, newValue) => {
            setFieldValue('incident.incidentCause', newValue ? newValue.coverCauseId : '');
          }}
          isOptionEqualToValue={(option, value) => option.coverCauseId === value?.coverCauseId}
          renderInput={(params) => <TextField {...params} label="Velg en assistanseårsak" variant="outlined" fullWidth error={Boolean(errors.incident?.incidentCause)} helperText={errors.incident?.incidentCause} />}
          renderGroup={(params) => (
            <Box key={params.group}>
              <Typography variant="h3" fontWeight={'bold'} marginTop={2} marginLeft={2}>
                {categoryTranslations[params.group] || params.group}
              </Typography>
              <Divider />
              {params.children}
            </Box>
          )}
        />
      </FormControl>
    </Tooltip>
  );
};
