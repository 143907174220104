import React, { useState, useEffect, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import CancelIcon from '@mui/icons-material/Cancel';

import { searchAddress } from '../../../../api/azureMapsService';
import { LocationMenuItem } from '../../../menu/LocationMenu';
import { LocationHelperText } from './LocationHelperText';
import useSearchStore from '../../../../hooks/store/useSearchStore';

export const LocationSearch = ({ isMapOpen, onMapToggle }) => {
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSettingValues, setIsSettingValues] = useState(false);
  const [field, meta, helpers] = useField('location.address');
  const [isOpen, setIsOpen] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const { setSearchResult } = useSearchStore();
  const { values, setFieldValue } = useFormikContext();
  const [missingFields, setMissingFields] = useState([]);

  const validateMissingFields = useCallback(() => {
    const { address, country, countryCodeISO, postalCode, lon, lat } = values.location || {};
    const missing = [];
    if (!address) missing.push('Adresse');
    if (!country) missing.push('Land');
    if (!countryCodeISO) missing.push('Landkode (ISO)');
    if (!postalCode) missing.push('Postkode');
    if (!lon) missing.push('Lengdegrad (Lon)');
    if (!lat) missing.push('Breddegrad (Lat)');
    setMissingFields(missing);
  }, [values.location]);

  useEffect(() => {
    validateMissingFields();
  }, [values.location, validateMissingFields]);

  const handleOptionSelect = useCallback(
    (selectedOption) => {
      setIsOpen(false);

      if (selectedOption) {
        const { streetName, streetNumber, freeformAddress, country, countryCode, municipality, postalCode } = selectedOption.address || {};
        const { position } = selectedOption || {};

        const name = streetName;
        const number = streetNumber || '';
        const address = `${name} ${number}`.trim();

        setSearchResult(selectedOption);

        helpers.setValue(address);
        helpers.setTouched(true);
        setIsSettingValues(true);
        setIsAddressSelected(true);
        setInputValue(freeformAddress || '');

        setTimeout(() => setIsSettingValues(false), 100);

        setFieldValue('location.city', municipality || '');
        setFieldValue('location.country', country || '');
        setFieldValue('location.countryCodeISO', countryCode || '');
        setFieldValue('location.postalCode', postalCode || '');
        setFieldValue('location.lon', position?.lon || '');
        setFieldValue('location.lat', position?.lat || '');
      }
    },
    [helpers, setFieldValue, setSearchResult]
  );

  useEffect(() => {
    if (isAddressSelected) return;

    const fetchResults = async () => {
      if (inputValue.length > 2) {
        setLoading(true);
        try {
          const addressResults = (await searchAddress(inputValue)).map((result) => ({
            ...result,
            label: result?.address?.freeformAddress || result?.poi?.name || 'Unknown',
          }));
          setResults(addressResults);
          setIsOpen(addressResults.length > 0);
        } catch (error) {
          setResults([]);
        } finally {
          setLoading(false);
        }
      } else {
        setResults([]);
        setIsOpen(false);
      }
    };

    const debounceTimer = setTimeout(fetchResults, 300);
    return () => clearTimeout(debounceTimer);
  }, [inputValue, isAddressSelected]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && results.length === 1) {
      handleOptionSelect(results[0]);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Autocomplete
            fullWidth
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            options={results}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.label}
            loading={loading}
            inputValue={inputValue}
            onChange={(_, newValue) => {
              if (newValue) {
                handleOptionSelect(newValue);
              }
            }}
            onInputChange={(_, newInputValue, reason) => {
              if (reason === 'input') {
                setInputValue(newInputValue);
                setIsAddressSelected(false);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Søk opp skadested"
                variant="outlined"
                fullWidth
                onKeyDown={handleKeyPress}
                error={Boolean(meta.touched && meta.error && !isSettingValues)}
                helperText={meta.touched && meta.error && !isSettingValues ? meta.error : null}
              />
            )}
            renderOption={(props, option) => <LocationMenuItem key={option.id} option={option} {...props} handleOptionSelect={handleOptionSelect} />}
            noOptionsText="Ingen resultater funnet"
            loadingText="Søker..."
          />
        </Box>
        <Box sx={{ marginLeft: '8px', flexShrink: 0 }}>
          <Button onClick={onMapToggle} variant="contained">
            {isMapOpen ? <CancelIcon /> : <MapIcon />}
          </Button>
        </Box>
      </Box>
      {meta.touched && missingFields.length > 0 ? (
        <LocationHelperText severity="warning" message={`Mangler nødvendig informasjon: ${missingFields.join(', ')}. Forsøk å flytt pinnen inne i kartet.`} />
      ) : (
        field.value && <LocationHelperText severity="success" message={`Skadestedet er satt til: ${field.value}`} />
      )}
    </Box>
  );
};
